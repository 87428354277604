
import Estimator from './components/common/estimator';
import "./App.css"
import heart from "./heart.svg"
function App() {
  return (
    <div className="App">
      <div className="container d-flex min-vh-100">
        <div className="m-auto w-100">
          <h1 className="text-center mb-5">
            Simulateur Impôt
          </h1>
          <div>
            <Estimator/>
          </div>
          {/* <div>
          <div className="text-center mt-5">Créé par le toto avec <span class="animate__heartBeat ms-2"><img src={heart} alt="coeur" width="20px" className="fas fa-heart animate__heartBeat"/></span></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default App;
