import React from 'react';
import simulatorService from '../../services/simulatorService';

export default class Sas extends React.Component {

  SASImpotSociete15 = 38120;

  constructor(props) {
    super(props)

    this.state = {
      dividende: null,
      tresorerie: null,
      salaire: null,
    }
  }

  SASbeneficeCharge(gain) {
    let charges = 0;
    if (gain > this.SASImpotSociete15) {
      charges += this.SASImpotSociete15 * 0.15;
      charges += (gain - this.SASImpotSociete15) * 0.28;
      return charges
    } else {
      return gain * 0.15;
    }
  }

  SASOldRevenuCharge(benefice) {
    let gain = benefice;
    return (simulatorService.IR(gain) * 0.60) + gain * 0.155;
  }

  SASNewRevenuCharge(benefice) {
    return benefice * 0.30
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {
    const {gain, charge} = this.props;
    const {dividende, tresorerie, salaire} = this.state;
    let beneficeWithoutSalaire = parseFloat(gain || 0) - parseFloat(charge || 0)
    let benefice = beneficeWithoutSalaire - parseFloat(salaire || 0);
    let beneficeCharge = this.SASbeneficeCharge(benefice);
    let withoutBeneficeCharge = benefice - beneficeCharge;
    let toDistribute = withoutBeneficeCharge - parseFloat(tresorerie || 0) - parseFloat(dividende || 0);
    let oldRegimeCharge = this.SASOldRevenuCharge(dividende);
    let newRegimeCharge = this.SASNewRevenuCharge(dividende);
    let salaireNet = salaire * 0.35;
    let oldNetResult = dividende - oldRegimeCharge + salaireNet + charge;
    let newNetResult = dividende - newRegimeCharge + salaireNet + charge;
    let thresholdBenefice = beneficeWithoutSalaire - this.SASImpotSociete15;

    return <div className="bg-light rounded-2 p-3">
      <div className="mb-3 fw-bold">Société SAS</div>
      <div className="mb-3">
        <div className="mb-3">
          <label className="mb-2" htmlFor="">Salaire BRUT</label>
          <input className="form-control" type="text" value={salaire} name="salaire" onChange={this.handleChange} />
          {beneficeWithoutSalaire > this.SASImpotSociete15 && <small style={{fontSize: "0.6rem"}} className="text-secondary">{thresholdBenefice} € pour passer sous le seuil des 28%</small>}
        </div>
        <div className="mb-3">
          <div className="text-secondary">
            Bénéfice
          </div>
          <div>
            {simulatorService.format(benefice)}
          </div>
        </div>
        <div className="mb-3 position-relative">
          <div className="text-secondary">
            Impot sur le bénéfice
          </div>
          <small style={{fontSize: "0.6rem", position: "absolute", right: 0}} className={benefice > this.SASImpotSociete15 ? "text-danger" : "text-primary"}>{benefice > this.SASImpotSociete15 ? "Tranche de 28%" : "Tranche de 15%"}</small>
          <div>
            {simulatorService.format(beneficeCharge)}
          </div>
        </div>
        <div className="mb-3">
          <div className="text-secondary">
            Restant
          </div>
          <div>{simulatorService.format(withoutBeneficeCharge)}</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className="text-secondary">
            A distibuer
          </div>
          <div className="">
            {simulatorService.format(toDistribute)}
          </div>
        </div>
        <div className="col">
          <div className="text-secondary">
            Trésorerie
          </div>
          <div>
            {simulatorService.format(tresorerie || 0)}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="">Dividende</label>
          <input className="form-control" type="text" value={dividende} name="dividende" onChange={this.handleChange} />
        </div>
        <div className="col-6">
          <label htmlFor="">Trésorerie</label>
          <input className="form-control" type="text" value={tresorerie} name="tresorerie" onChange={this.handleChange} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <div className="text-secondary">
              Ancien régime d’imposition
            </div>
            <div>{simulatorService.format(oldRegimeCharge)}</div>
          </div>
          <div className="">
            <div className="bg-dark rounded-2 py-3">
              <div className="text-secondary">
                Revenu net
              </div>
              <div className="text-white">
                {simulatorService.format(oldNetResult)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <div className="text-secondary">
              Nouveau régime d’imposition
            </div>
            <div>{simulatorService.format(newRegimeCharge)}</div>
          </div>
          <div className="">
            <div className="bg-dark rounded-2 py-3">
              <div className="text-secondary">
                Revenu net
              </div>
              <div className={"text-white"}>
                {simulatorService.format(newNetResult)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}