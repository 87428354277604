import React from 'react';
import simulatorService from '../../services/simulatorService';

export default class AutoEntrepreneur extends React.Component {


  beneficeCharge() {
    return this.props.gain * 0.22;
  }

  revenuCharge() {
    let gain = this.props.gain - this.beneficeCharge();
    return simulatorService.IR(gain);
  }

  netGain() {
    return this.props.gain - this.revenuCharge() - this.beneficeCharge();
  }

  render() {
    const {gain, charge} = this.props;
    let benefice = parseFloat(gain);
    let beneficeCharge = this.beneficeCharge(benefice);
    let withoutBeneficeCharge = benefice - beneficeCharge;
    let revenuCharge = this.revenuCharge(withoutBeneficeCharge);
    let netResult = withoutBeneficeCharge - revenuCharge;
    return <div className="bg-light rounded-2 p-3">
    <div className="mb-3 fw-bold">Auto-entrepreneur</div>
      <div className="mb-3">
        <div className="mb-3">
          <div className="text-secondary">
            Bénéfice
          </div>
          <div>
            {simulatorService.format(benefice)}
          </div>
        </div>
        <div className="mb-3">
          <div className="text-secondary">
            Impot sur le bénéfice
          </div>
          <div>{simulatorService.format(beneficeCharge)}</div>
        </div>
        <div className="mb-3">
          <div className="text-secondary">
            Restant
          </div>
          <div>{simulatorService.format(withoutBeneficeCharge)}</div>
        </div>
      </div>
      <div className="mb-3">
        <div className="text-secondary">
        Impot sur le revenu
        </div>
        <div>
          {simulatorService.format(revenuCharge)}
        </div>
      </div>
      <div className="bg-dark rounded-2 py-3">
        <div className="text-secondary">
          Revenu net
        </div>
        <div className={benefice >= 0 ? "text-white" : "text-danger"}>
          {simulatorService.format(netResult)}
        </div>
      </div>
    </div>
  }
}