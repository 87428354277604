import React from 'react';
import AutoEntrepreneur from './autoEntrepreneur';
import Sas from './sas';

export default class Estimator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gain: "",
      charge: ""
    }
  }

  charge() {
    return this.state.charge ? parseFloat(this.state.charge) : 0;
  }

  gain() {
    return this.state.gain ? parseFloat(this.state.gain) : 0;
  }

  render() {
    return <div>
      <div className="mb-4">
        <input type="text" placeholder="Chiffre d'affaire" className="form-control form-control-lg mb-2" onChange={(e) => this.setState({gain: e.target.value})} value={this.state.gain} />
        <input type="text" placeholder="Charge" className="form-control form-control-lg" onChange={(e) => this.setState({charge: e.target.value})} value={this.state.charge} />
      </div>
      <div className="row text-center">
        <div className="col-md-6">
          <AutoEntrepreneur gain={this.gain()} charge={this.charge()} />
        </div>
        <div className="col-md-6">
          <Sas gain={this.gain()} charge={this.charge()}/>
        </div>
      </div>
    </div>
  }
}