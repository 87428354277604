class SimulatorService {

  tranches = [
    {limit: 10084, rate: 0},
    {limit: 25710, rate: 11},
    {limit: 73516, rate: 30},
    {limit: 158122, rate: 41},
    {limit: 999999999999, rate: 45},
  ]

  format(number) {
    return new Intl.NumberFormat("fr-FR", { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(number)
  }

  getImpotRate(net, gain) {
    if (!gain) return  '0.00 %';
    return ((((net / gain) * 100) - 100) * -1).toFixed(2) + ' %'
  }

  IR(gain) {
    let charges = 0;
    let previousLimit = 0
    for (const tranche of this.tranches) {
      if (gain - tranche.limit > 0) {
        charges += (tranche.limit - previousLimit) * (tranche.rate / 100);
      } else {
        charges += (gain - previousLimit) * (tranche.rate / 100);
        break
      }
      previousLimit = tranche.limit;
    }
    return charges;
  }
}

export default new SimulatorService();